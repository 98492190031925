<template>
  <div class="UserAgreement">
    <div class="title">《水费易生活缴费服务协议》</div>
    <div class="Info">
      <p class="p1">甲方：个人缴费客户</p>
      <p class="p1">乙方：重庆爱派科技有限公司</p>
      <p class="p2">
        双方本着平等互利的原则，就水费易生活缴费服务相关事宜达成《水费易生活缴费服务协议》(以下简称本协议)。
      </p>
      <div class="con">
        <p class="p1">第一条 水费易生活缴费服务</p>
        <p class="p2">
          水费易生活缴费服务是乙方根据与乙方合作的第三方（入驻水费易平台的水电气等相关企事业单位）提供的用户自助查询与缴费服务及其它便民生活服务。乙方登陆注册水费易平台后，根据需要绑定相关用户信息（用户信息编码请联系所属单位咨询）进行查询账单，并根据企业开通支付渠道的情况实现在线缴费业务。乙方根据甲方绑定的用户信息并选择相应的服务指令，返回相关数据及交易信息等技术服务。（以下简称“本服务”）。
        </p>
        <p class="p2">
          本协议适用于“水费易”增值业务，包括：便民生活、自助抄表、快递查询、天气预报等。甲方使用本服务，乙方暂不收取费用，但乙方保留收取费用的权利，最终以乙方公告为准。
        </p>
      </div>
      <div class="con">
        <p class="p1">第二条 签约</p>
        <p class="p2">
          甲方自愿选择是否使用水费易生活缴费服务，如选择使用本服务，需核实甲方本人实名身份，通过电子方式签署本协议。甲方使用本服务时，如其使用的服务功能涉及到乙方其他业务规定或规则时，需同时遵守。
        </p>
      </div>
      <div class="con">
        <p class="p1">第三条 水费易生活缴费服务</p>
        <p class="p2">
          1.甲方可随时申请使用本服务，亦可随时申请停止本服务，此操作均可通过甲方自助设置完成。
        </p>
        <p class="p2">
          2.甲方选择本服务，即表示甲方授权委托乙方按照其指令返回相关查询数据和交易数据，同时甲方亦授权委托乙方或乙方合作的第三方有权根据甲方指令或授权使用资金用于水电气缴费等交易。甲方授权委托乙方有权根据甲方指令进行相应操作并通知乙方合作的第三方，且甲方授权委托乙方合作的第三方有权根据甲方或乙方指令将甲方相应银行账户/卡中的相应资金扣划至相应账户以进行生活缴费服务。
        </p>
        <p class="p2">
          3.甲方使用本服务，乙方暂不收取手续费，但乙方保留收取费用的权利，最终以乙方公告为准。
        </p>
        <p class="p2">
          4.乙方有权根据自身业务的发展，调整本服务的内容；乙方有权对本服务进行升级、改造；另外，乙方有权随时停止本服务。
        </p>
        <p class="p2">
          5.乙方有权拒绝或取消不符合要求的甲方使用服务资格，而无须预先通知甲方。甲方从事非法交易或不正当活动的，乙方有权停止其服务，并有权追溯其相关法律责任。
        </p>
        <p class="p2">
          6.甲方在发生资金交易时应确保其交易的对象准确性，即所绑定的用户信息与收款单位是经过甲方本人确认无误才认可发生的交易行为。如因未仔细核对交易对象信息导致付款至他人账户而发生资金损失的，由甲方自行负责。
        </p>
        <p class="p2">
          7.本服务的收款主体为与乙方合作的第三方(即入驻水费易平台的水电气企事业单位)，乙方只提供相关的数据和技术服务。如发生入账错误，请联系收款单位进行咨询处理。
        </p>
      </div>
      <div class="con">
        <p class="p1">第四条 用户资料信息及保密</p>
        <p class="p2">
          1.出于业务安全的需要，甲方需提供真实姓名、证件号码以及真实的手机号码作为核实客户身份的重要信息。
        </p>
        <p class="p2">
          2.乙方对甲方提供的相关身份资料及在交易中产生的交易记录负有保密义务，但有权利根据法律法规规定向有权查询的法定机构，或为完成交易指令的需要向第三方提供。
        </p>
        <p class="p2">
          3.乙方有权对甲方的信息，包括但不限于注册资料和交易记录等进行查阅及验证。
        </p>
        <p class="p2">
          4.乙方发现注册资料或交易行为中存在问题，或可能会导致不良后果的，有权向甲方发出询问，要求甲方进一步提供必要的信息或直接对该交易、甲方及相关的交易作出相应的处理。
        </p>
      </div>

      <div class="con">
        <p class="p1">第五条 协议修改</p>
        <p class="p2">
          1.乙方可随时及不时修订本协议。本协议的任何修订及/或增补，经乙方在网站发布、公告或以乙方认为合适的其他方式向甲方作出合理通知后，即开始生效。
        </p>
        <p class="p2">
          2.如果甲方不同意乙方对本协议相关条款所做的修改，甲方有权停止使用乙方提供的服务。如果甲方继续使用乙方提供的服务，则视为其同意本协议相关条款的修改。
        </p>
      </div>
      <div class="con">
        <p class="p1">第六条 差错处理</p>
        <p class="p2">
          甲方发现自身未按规定操作，或由于自身其他原因造成水费易生活缴费指令未执行、未适当执行、延迟执行的，应及时通过拨打客服电话或通过在线客服通知乙方。乙方应积极调查并告知用户调查结果。
        </p>
      </div>
      <div class="con">
        <p class="p1">第七条 免责条款</p>
        <p class="p2">
          在使用本服务过程中，乙方保证按照甲方指令进行水费易生活缴费。对于由于甲方原因以及非乙方原因的其他各种原因造成的未能按期缴费、缴费金额不符等情况，乙方不承担任何责任。
        </p>
      </div>
      <div class="con">
        <p class="p1">第八条 法律适用与争议解决</p>
        <p class="p2">
          本协议的成立、生效、履行和解释，均适用中华人民共和国法律；法律无明文规定的，应适用乙方及其合作的第三方相关的业务规定和通行的行业惯例。
        </p>
        <p class="p2">
          因使用本服务所产生的任何争议，由双方协商解决；协商不成的，任何一方均有权将争议提交重庆市仲裁委员会仲裁。
        </p>
      </div>

      <div class="con">
        <p class="p2 p3">重庆爱派科技有限公司</p>
        <p class="p2 p3">2019年1月1日</p>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.UserAgreement {
  width: 95%;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 0;
    color: rgb(10, 10, 10);
  }

  .con {
  }
  .p1 {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: rgb(10, 10, 10);
  }

  .p2 {
    font-size: 16px;
    text-align: left;
    color: #666;
    text-indent: 32px;
    margin: 8px 0;
  }

  .p3 {
    text-align: left;
    text-align: right;
    color: #333;
  }
}
</style>
